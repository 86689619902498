<script setup lang="ts">
import { useBookingDataStore } from '@/stores/bookingData';

const bookingData = useBookingDataStore();

function hasLogement() {
  const logement = bookingData.getService('logement');
  return logement && logement.quantity > 0;
}

</script>
<template>
  <div v-if="hasLogement()">
    <div class="bpa-front-module-heading">Logement</div>
    <p>Nombre de personnes: {{ bookingData.getService('logement')?.quantity }}</p>
    <br />
  </div>
</template>

<style scoped>
@media only screen and (max-width: 576px) {
  .menu-summary {
    margin: 0px 15px;
  }
}
</style>
