<script setup lang="ts">
import { getOptions } from "@/api/api";
import { itemsAmount } from "@/api/types";
import { mapItemToBookingItem } from "@/services/price";
import { useBookingDataStore } from "@/stores/bookingData";
import { onMounted, ref, watch } from "vue";

const options = ref(Array());

const amount = ref<itemsAmount>({});

const bookingData = useBookingDataStore();
// const textStore = useTextStore();

onMounted(async () => {
  const s = await getOptions();
  if (s?.success && s.content) {
    console.log("vv", s.content);
    options.value = s.content.filter((o) => o.Id !== "chien" || allowBrunch());
    options.value.forEach((o) => {
      amount.value[o.Id] = 0;
      watch(
        () => amount.value[o.Id],
        async (curr, prev) => {
          console.log("amount option change", o.Id, curr);
          bookingData.updateOptions(
            mapItemToBookingItem(
              options.value.find((f) => f.Id == o.Id),
              amount.value[o.Id]
            )
          );
        }
      );
    });

    // Load current data
    if (Array.isArray(bookingData.options)) {
      bookingData.options.forEach((s) => {
        amount.value[s.id] = s.quantity;
      });
    }
  }

  // TEXT_entreechaude.value = await textStore.getText('infoentree') || '';
});

function toggleType(type: string) {
  if (type.includes("brunch") && !allowBrunch()) {
    return;
  }
  if (amount.value[type] == 0) {
    amount.value[type] = 1;
    if (type.includes("brunch")) {
      const currentAmount = Math.max(
        ...options.value
          .filter((o) => o.Id.includes("brunch"))
          .map((i) => amount.value[i.Id])
      );
      amount.value[type] = currentAmount;
      options.value.forEach((o) => {
        if (o.Id !== type && o.Id.includes("brunch")) {
          amount.value[o.Id] = 0;
        }
      });
    }
  }
}

function allowBrunch() {
  return bookingData.services.some((s) => s.id == "logement" && s.quantity > 0);
}
</script>

<template>
  <div
    class="bpa-front-tabs--panel-body __bpa-is-active eauget-booking-step-type"
  >
    <div class="bpa-front-default-card">
      <!---->
      <div class="bpa-front-dc--body">
        <!---->
        <div class="el-row">
          <div
            class="el-col el-col-24 el-col-xs-24 el-col-sm-24 el-col-md-24 el-col-lg-24 el-col-xl-24"
          >
            <div class="bpa-front-module-container bpa-front-module--service">
              <div class="bpa-front-module-heading">
                Options supplémentaires
              </div>
              <div class="bpa-front-module--service-items-row">
                <card-item
                  v-for="item in options"
                  :key="item.Id"
                  :title="item.Titre"
                  :price="item.Prix"
                  :canAdd="!item.Id.includes('brunch') || allowBrunch()"
                  :description="item.Description"
                  :pictureUrl="item.Photo"
                  v-model="amount[item.Id]"
                  :isSelected="amount[item.Id] > 0"
                  :hasQuantity="true"
                  :maxValue="item.Maximum"
                  @click="toggleType(item.Id)"
                  :disabled="item.Id.includes('brunch') && !allowBrunch()"
                ></card-item>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bpa-front-dc--footer">
        <div class="el-row">
          <div class="el-col el-col-24">
            <div class="bpa-front-tabs--foot footer-eauget">
              <subtotal></subtotal
              ><!----><button
                type="button"
                @click="$emit('previousStep')"
                class="el-button bpa-front-btn bpa-front-btn__medium bpa-front-btn--borderless el-button--default"
              >
                <!----><!----><span
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    enable-background="new 0 0 24 24"
                    viewBox="0 0 24 24"
                  >
                    <rect fill="none" height="24" width="24"></rect>
                    <path
                      d="M9.7,18.3L9.7,18.3c0.39-0.39,0.39-1.02,0-1.41L5.83,13H21c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1H5.83l3.88-3.88 c0.39-0.39,0.39-1.02,0-1.41l0,0c-0.39-0.39-1.02-0.39-1.41,0L2.7,11.3c-0.39,0.39-0.39,1.02,0,1.41l5.59,5.59 C8.68,18.68,9.32,18.68,9.7,18.3z"
                    ></path>
                  </svg>
                  Retour
                </span>
              </button>
              <button
                type="button"
                @click="$emit('nextStep')"
                class="el-button bpa-front-btn bpa-front-btn__medium bpa-front-btn--primary el-button--default eauget-next-step"
              >
                <!----><!----><span>
                  Suivant :&nbsp;<strong>Coordonnées</strong>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    enable-background="new 0 0 24 24"
                    viewBox="0 0 24 24"
                  >
                    <rect fill="none" height="24" width="24"></rect>
                    <path
                      d="M14.29,5.71L14.29,5.71c-0.39,0.39-0.39,1.02,0,1.41L18.17,11H3c-0.55,0-1,0.45-1,1v0c0,0.55,0.45,1,1,1h15.18l-3.88,3.88 c-0.39,0.39-0.39,1.02,0,1.41l0,0c0.39,0.39,1.02,0.39,1.41,0l5.59-5.59c0.39-0.39,0.39-1.02,0-1.41L15.7,5.71 C15.32,5.32,14.68,5.32,14.29,5.71z"
                    ></path></svg
                ></span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="bpa-fm--service__advance-options">
        <div class="bpa-sao--body">
          <!---->
          <!---->
        </div>
        <div class="bpa-sao--footer">
          <button
            type="button"
            class="el-button bpa-front-btn bpa-front-btn--borderless bpa-front-btn__medium el-button--default"
          >
            <!----><!----><span>Annuler</span>
          </button>
          <button
            type="button"
            class="el-button bpa-front-btn bpa-front-btn__medium bpa-front-btn--primary el-button--default"
          >
            <!----><!----><span>Continuer</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!---- Choice of brunch or not -->
  <!--<span v-if="selectedType.includes('logement') && false">
                <div class="bpa-front-module-heading">Brunch</div>
                <div class="bpa-front-module--service-items-row">
                  <div class="bpa-fm--si--col">
                    <div class="bpa-front-module--service-item __bpa-is-description-enable"
                      :class="{ '__bpa-is-selected': brunch }">
                      <div id="bap-service-1" class="bpa-front-si-card">
                        <div class="bpa-front-si-card__row" :class="{ 'is-disabled': amountPeople > 6 }"
                          @click="toggleBrunch()">
                          <div class="bpa-front-si-card--checkmark-icon" v-if="brunch">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                              <path d="M0 0h24v24H0V0z" fill="none" />
                              <path
                                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29 5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
                            </svg>
                          </div>
                          <div class="bpa-front-si-card__left"><img
                              src="https://eauget.com/wp-content/uploads/bookingpress/1689807487_Booking_image.png"
                              alt=""></div>
                          <div class="bpa-front-si__card-body" :class="{ 'is-disabled': amountPeople > 6 }">
                            <div class="bpa-front-si__card-body--heading">Brunch</div>
                            <p class="--bpa-is-desc">Brunch jusqu'à 18 personnes.</p>
                            <div class="bpa-front-si-cb__specs">
                              <div class="bpa-front-si-cb__specs-item">
                                <p>
                                  Tarif par personne : <strong class="--is-service-price">€xx</strong>
                                </p>
                              </div>
                            </div>
                            <div v-if="brunch">
                              <br />
                              <quantity label="Nombre de brunchs" @change="onQuantityBrunchChange"></quantity>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </span>-->
</template>

<style scoped></style>
