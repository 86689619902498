/**
 * main.ts
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Plugins
import { registerPlugins } from '@/plugins'

// Components
import App from './App.vue'
import { createPinia } from "pinia";

// Composables
import { createApp } from 'vue'

import * as Sentry from "@sentry/vue";

const pinia = createPinia();
const app = createApp(App).use(pinia);

Sentry.init({
  app,
  dsn: "https://8f2e0091493643e49cda807e5395d5c6@o229109.ingest.us.sentry.io/4508400108240896",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\reservation\.eauget\.com/],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

registerPlugins(app)

app.mount('#app')
