import { BookingItem } from "@/stores/bookingData";

// const bookingData = useBookingDataStore();

export function getTotalPrice(services: BookingItem[], foods: BookingItem[], drinks: BookingItem[], options: BookingItem[]) {
  console.log('getTotalPrice', services, foods);
  return (services.reduce((total, service) => total +
    (service.quantity > 0 ? (parseFloat(service.packagePrice.replaceAll(',', '.')) || 0) + service.quantity * (parseFloat(service.unitPrice.replaceAll(',', '.')) || 0) : 0)
    , 0)
    + foods.reduce((total, food) => total +
      (food.activated ? food.quantity * (parseFloat(food.packagePrice.replaceAll(',', '.')) || 0) : 0)
      , 0)
    + drinks.reduce((total, drink) => total +
      (drink.activated ? drink.quantity * (parseFloat(drink.packagePrice.replaceAll(',', '.')) || 0) : 0)
      , 0)
    + options.reduce((total, option) => total +
      (option.activated ? option.quantity * (parseFloat(option.packagePrice.replaceAll(',', '.')) || 0) : 0)
      , 0)).toFixed(2);

}

/*export function totalBookingPrice() {
  return getTotalPrice(bookingData.services);
}*/

export function mapItemToBookingItem(item: any, quantity: number, options: BookingItem[] = []): BookingItem {
  return {
    id: item.Id,
    activated: true,
    quantity,
    packagePrice: item.Prix,
    unitPrice: item['Prix par personne'],
    options,
    meta: item
  }
}
