<script setup lang="ts">
import { useBookingDataStore } from '@/stores/bookingData';
import { onMounted } from 'vue';

const bookingData = useBookingDataStore();

onMounted(async () => {
  console.log('summary menu');
})

</script>

<template>
  <div class="bpa-front-tabs--panel-body __bpa-is-active eauget-booking-step-type">
    <div class="bpa-front-default-card"><!---->
      <div class="bpa-front-dc--body"><!---->
        <div class="el-row">
          <div class="el-col el-col-24 el-col-xs-24 el-col-sm-24 el-col-md-24 el-col-lg-24 el-col-xl-24">
            <div class="bpa-front-module-container bpa-front-module--service">
              <h2 class="menu-summary">Merci d'avoir confirmé votre choix !</h2>
              <br />
              <img src="https://eauget.com/wp-content/uploads/2024/05/IMG-20240516-WA0049.jpg?x80868">
              <food-summary />
              <menu-summary />
              <div class="bpa-front-module-heading">Allergies</div>
              <div class="allergy-summary">
                <p>{{ bookingData.clientInfo.allergy }}</p>
              </div>

              <div class="bpa-front-module--service-items-row">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bpa-front-dc--footer">
        <div class="el-row">
          <div class="el-col el-col-24">
            <div class="bpa-front-tabs--foot"></div>
          </div>
        </div>
      </div>
      <div class="bpa-fm--service__advance-options">
        <div class="bpa-sao--body"><!----> <!----></div>
        <div class="bpa-sao--footer"><button type="button"
            class="el-button bpa-front-btn bpa-front-btn--borderless bpa-front-btn__medium el-button--default"><!----><!----><span>Annuler</span></button>
          <button type="button"
            class="el-button bpa-front-btn bpa-front-btn__medium bpa-front-btn--primary el-button--default"><!----><!----><span>Continuer</span></button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
img {
  width: 50%;
  float: right;
}

@media only screen and (max-width: 576px) {
  .menu-summary, p {
    margin: 0px 15px !important;
  }
}</style>
