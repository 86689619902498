<template>
  <div class="wrapper">
    <div class="bpa-field-main-col el-col el-col-24 el-col-xs-24 el-col-sm-24 el-col-md-24 el-col-lg-24 el-col-xl-24">
      <div class="bpa-bdf--single-col-item">
        <div class="el-form-item"><label v-if="label" :for="name" class="el-form-item__label"><span
              class="bpa-front-form-label">{{ label }}</span></label>
          <div class="el-form-item__content"> <!----> <!----> <!----> <!----> <!----> <!---->
            <!----> <!---->
            <div>
              <div class="bpa-front-form-control el-textarea"><textarea :id="name"
                  :class="{ field_error: errorMessage, valid: meta.valid }" autocomplete="off" v-model.lazy="value"
                  :placeholder="placeholder" rows="3" class="el-textarea__inner"
                  style="min-height: 39.9333px;"></textarea><!---->
                <small :class="errorMessage ? 'error_message' : ''">{{ errorMessage }}</small>
              </div>
            </div> <!----> <!----><!---->
          </div>
        </div> <!---->
      </div> <!----> <!---->
    </div>

  </div>
</template>

<script setup>
import { useField } from 'vee-validate'

const props = defineProps({
  name: {
    type: String,
    required: true
  },
  type: String,
  placeholder: String,
  label: String
})

const { value, errorMessage, meta } = useField(() => props.name)
</script>
<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  gap: 3px;
}

.error_message {
  color: red;
}

.field_error {
  border: 1px solid red !important;
}

.input {
  width: 100%;
  height: 30px;
  border-radius: 5px;
  padding: 10px;
  border: 0.5px solid gray;
}

.input:focus {
  outline: none;
}

.valid {
  border: 1px solid green !important;
}

label {
  font-size: 14px;
  font-weight: 600;
}
</style>
