<script setup lang="ts">
import { getFoods } from '@/api/api';
import { useBookingDataStore } from '@/stores/bookingData';
import { formatDate } from '@/utils.ts/date';
import { onMounted, ref } from 'vue';


const foods = ref(Array());

const bookingData = useBookingDataStore();

onMounted(async () => {
  const s = await getFoods();
  if (s?.success && s.content) {
    foods.value = s.content;
  }
});

function getFoodName(id: string) {
  return foods.value.find(f => f.Id == id)?.Titre || '';
}

function orderedFoods() {
  return bookingData.foods.filter(v => v.quantity > 0);
}

</script>
<template>
  <div>
    <div class="bpa-front-module-heading">Votre réservation du {{ formatDate(bookingData.date) }}</div>
    <div class="menu-summary" v-for="item in orderedFoods()" :key="item.id">{{ item.quantity }} x {{
      getFoodName(item.id) }}</div>
    <br />

  </div>
</template>

<style scoped>
@media only screen and (max-width: 576px) {
  .menu-summary {
    margin: 0px 15px;
  }
}
</style>
