<script setup lang="ts">
import { fetchFoodOrder, getMenu, saveMenuChoice } from "@/api/api";
import { itemsAmount } from "@/api/types";
import { Mode } from "@/api/types/mode.enum";
import { Steps } from "@/api/types/steps.enum";
import { mapItemToBookingItem } from "@/services/price";
import { useBookingDataStore } from "@/stores/bookingData";
import { useBookingStepStore } from "@/stores/bookingStep";
import { useTextStore } from "@/stores/texts";
import { onMounted, ref, watch } from "vue";

const emit = defineEmits(['nextStep', 'previousStep'])

const loading = ref(true);
const amountEntree = ref<itemsAmount>({});
const amountPlat = ref<itemsAmount>({});
const amountTotalPlat = ref(0);
const amountTotalEntree = ref(0);
const amountTotalVegePlat = ref(0);
const amountTotalVegeEntree = ref(0);
const menu = ref(Array());
const TEXT_entreechaude = ref("");
const TEXT_infovege = ref("");
const bookingData = useBookingDataStore();
const bookingStep = useBookingStepStore();
const textStore = useTextStore();
const dialogVege = ref(false);
const allergy = ref('');

onMounted(async () => {
  console.log('mounted menu2', bookingStep.getMode(), bookingStep.step, bookingStep.bookingId);
  if (bookingStep.getMode() == Mode.Menu) {
    const data = await fetchFoodOrder(bookingStep.bookingId);
    console.log('data', data);
    if (data?.success && data.content.alreadyChosen) {
      console.log('step summary');
      bookingStep.goToStep(Steps.SummaryMenu, true);
    }
    await bookingData.fetchOrder(data?.content);
  }

  if (!bookingData.date) {
    throw new Error("booking date undefined");
  }
  const s = await getMenu(bookingData.date);
  if (s?.success && s.content) {
    const thresholdDate = s.content.map(e => { const d = new Date(e['Fin']); d.setHours(12); return d; }).filter((f: Date) => f >= bookingData.date!).reduce((prev, curr) => prev < curr ? prev : curr);
    console.log('thresholdDate', thresholdDate);
    menu.value = s.content.filter((e) =>  {
      const d = new Date(e['Fin']);
      d.setHours(12);
      return (e.Type == "entree" && onlyColdEntree() ? e.Preparation == "froid" : true) && d.getTime() == thresholdDate.getTime()
    });
    menu.value.forEach((o) => {
      if (o.Type == "entree") {
        amountEntree.value[o.Id] = 0;
        watch(
          () => amountEntree.value[o.Id],
          async (curr, prev) => {
            bookingData.updateMenu(
              mapItemToBookingItem(
                menu.value.find((f) => f.Id == o.Id),
                amountEntree.value[o.Id]
              )
            );
          }
        );
      }
      if (o.Type == "plat") {
        amountPlat.value[o.Id] = 0;
        watch(
          () => amountPlat.value[o.Id],
          async (curr, prev) => {
            bookingData.updateMenu(
              mapItemToBookingItem(
                menu.value.find((f) => f.Id == o.Id),
                amountPlat.value[o.Id]
              )
            );
          }
        );
      }
    });

    // Load current data
    if (Array.isArray(bookingData.menu)) {
      bookingData.menu.forEach((s) => {
        if (s.id.includes("entree")) {
          amountEntree.value[s.id] = s.quantity;
        }

        if (s.id.includes("plat")) {
          amountPlat.value[s.id] = s.quantity;
        }
      });
    }

    if (bookingStep.getMode() == Mode.Menu) {
      console.log('menu mode', bookingData, bookingData.clientInfo);
      const ci = bookingData.clientInfo;
      if (ci?.allergy) {
        allergy.value = ci.allergy;
      }
    }
  }
  // amountTotal.value = bookingData.services.find(s => s.id == 'diner')?.quantity || 0;
  amountTotalEntree.value = bookingData.foods.reduce(
    (prev, curr) => prev + curr.quantity * (curr.id.at(0)! == "4" ? 2 : 1),
    0
  );
  amountTotalVegeEntree.value = bookingData.foods.reduce(
    (prev, curr) => prev + (curr.meta.Vegetarien == 'oui' ? (curr.quantity * (curr.id.at(0)! == "4" ? 2 : 1)) : 0),
    0
  );
  amountTotalPlat.value = bookingData.foods.reduce(
    (prev, curr) => prev + curr.quantity,
    0
  );
  amountTotalVegePlat.value = bookingData.foods.reduce(
    (prev, curr) => prev + (curr.meta.Vegetarien == 'oui' ? curr.quantity : 0),
    0
  );
  TEXT_entreechaude.value = (await textStore.getText("infoentree")) || "";
  TEXT_infovege.value = (await textStore.getText("infovege")) || "";

  loading.value = false;
});

function onlyColdEntree() {
  return !bookingData.foods.some((f) => f.id.at(0)! == "4" && f.quantity > 0);
}

function getAppetizer() {
  return menu.value.filter((m) => m.Type == "aperitif");
}

function getEntree() {
  return menu.value.filter((m) => m.Type == "entree");
}

function getPlat() {
  return menu.value.filter((m) => m.Type == "plat");
}

function getDessert() {
  return menu.value.filter((m) => m.Type == "dessert");
}

function totalSelectedEntree() {
  return Object.values(amountEntree.value).reduce(
    (p: number, c: number) => p + c,
    0
  );
}

function totalSelectedPlat() {
  return Object.values(amountPlat.value).reduce(
    (p: number, c: number) => p + c,
    0
  );
}

function toggleEntree(type: string) {
  if (
    amountEntree.value[type] == 0 &&
    totalSelectedEntree() < amountTotalEntree.value
  ) {
    amountEntree.value[type] = 1;
  }
}

function togglePlat(type: string) {
  if (
    amountPlat.value[type] == 0 &&
    totalSelectedPlat() < amountTotalPlat.value
  ) {
    amountPlat.value[type] = 1;
  }
}

function remainingAmountEntree() {
  return amountTotalEntree.value - totalSelectedEntree();
}

function remainingAmountPlat() {
  return amountTotalPlat.value - totalSelectedPlat();
}

function canGoNext() {
  return (
    totalSelectedEntree() == amountTotalEntree.value &&
    totalSelectedPlat() == amountTotalPlat.value
  );
}

function warnVege() {
  return (bookingData.menu.filter(m => m.meta.Type == 'entree' && m.meta.Vegetarien == 'oui').reduce((prev, curr) => prev + curr.quantity, 0) < amountTotalVegeEntree.value) ||
    (bookingData.menu.filter(m => m.meta.Type == 'plat' && m.meta.Vegetarien == 'oui').reduce((prev, curr) => prev + curr.quantity, 0) < amountTotalVegePlat.value)
}

function skipMenu2() {
  bookingData.clearMenu();
  emit('nextStep');
}

async function validateMenu(confirmVege = false) {
  console.log('VALIDATE !', bookingData.clientInfo.allergy);
  if (warnVege() && !confirmVege) {
    dialogVege.value = true;
  } else {
    const status = await saveMenuChoice(bookingData.getData());
    console.log("status", status);
    if (status?.success && status.content.status == 'saved') {
      bookingStep.goToStep(Steps.SummaryMenu, true);
    }
  }
}
</script>

<template>
  <div v-if="!loading" class="bpa-front-tabs--panel-body __bpa-is-active eauget-booking-step-type">
    <v-dialog v-model="dialogVege" width="auto">
      <v-card max-width="400" prepend-icon="mdi-alert"
        text="Vous n'avez pas choisi autant de plats et entrées végétariens que de menus végétariens commandés. Merci de confirmer que c'est bien ce que vous souhaitez."
        title="Plats et entrées végétariens">
        <template v-slot:actions>
          <v-btn @click="dialogVege = false">
            Annuler
          </v-btn>

          <v-btn @click="validateMenu(true)">
            Confirmer
          </v-btn>
        </template>
      </v-card>
    </v-dialog>
    <div class="bpa-front-default-card">
      <!---->
      <div class="bpa-front-dc--body">
        <!---->
        <div class="el-row">
          <div class="el-col el-col-24 el-col-xs-24 el-col-sm-24 el-col-md-24 el-col-lg-24 el-col-xl-24">
            <div class="bpa-front-module-container bpa-front-module--service">
              <food-summary v-if="bookingStep.getMode() == Mode.Menu" />
              <div class="bpa-front-module-heading">
              <button v-if="bookingStep.getMode() == Mode.Normal" type="button" @click="skipMenu2()"
                class="el-button bpa-front-btn bpa-front-btn__medium bpa-front-btn--primary el-button--default eauget-skip-step">
                <!----><!----><span>
                  <strong>Je préfère choisir les plats plus tard</strong>
                  <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24">
                    <rect fill="none" height="24" width="24"></rect>
                    <path
                      d="M14.29,5.71L14.29,5.71c-0.39,0.39-0.39,1.02,0,1.41L18.17,11H3c-0.55,0-1,0.45-1,1v0c0,0.55,0.45,1,1,1h15.18l-3.88,3.88 c-0.39,0.39-0.39,1.02,0,1.41l0,0c0.39,0.39,1.02,0.39,1.41,0l5.59-5.59c0.39-0.39,0.39-1.02,0-1.41L15.7,5.71 C15.32,5.32,14.68,5.32,14.29,5.71z">
                    </path>
                  </svg></span>
              </button>
              </div>
              <div class="bpa-front-module-heading">Choix des entrées et plats</div>
              <div class="bpa-front-module-heading">Mise en bouche</div>
              <card-item v-for="item in getAppetizer()" :key="item.Id" :title="item.Titre"
                :description="item.Description" :pictureUrl="item.Photo" v-model="amountTotalPlat" :isSelected="true"
                :hasQuantity="true" :editableQuantity="false" :canDelete="false"></card-item>
              <div class="bpa-front-module-heading">Entrées</div>
              <p>{{ amountTotalEntree }} entrées à choisir dont {{ amountTotalVegeEntree }} entrées végétariennes</p>
              <v-progress-linear :color="totalSelectedEntree() == amountTotalEntree
                ? 'green'
                : 'var(--bpa-pt-main-green)'
                " bg-opacity="0.4" :model-value="(totalSelectedEntree() * 100) / amountTotalEntree" height="25">
                <template v-slot:default>
                  <strong>{{ totalSelectedEntree() }} /
                    {{ amountTotalEntree }} entrées choisies</strong>
                </template>
              </v-progress-linear>
              <br />
              <div v-if="onlyColdEntree() && bookingStep.getMode() == Mode.Normal">
                <p>{{ TEXT_entreechaude }}</p>
                <br />
              </div>
              <div class="bpa-front-module--service-items-row">
                <card-item v-for="entree in getEntree()" :key="entree.Id" :title="entree.Titre" :canAdd="remainingAmountEntree() > 0"
                  :description="entree.Description" :pictureUrl="entree.Photo" v-model="amountEntree[entree.Id]"
                  :isSelected="amountEntree[entree.Id] > 0" :hasQuantity="true"
                  :maxValue="amountEntree[entree.Id] + remainingAmountEntree()"
                  :disabled="remainingAmountEntree() == 0 && amountEntree[entree.Id] == 0"
                  @click="toggleEntree(entree.Id)"></card-item>
              </div>
              <br />
              <div class="bpa-front-module-heading">Plats</div>
              <p>{{ amountTotalPlat }} plats à choisir dont {{ amountTotalVegePlat }} plats végétariens</p>
              <v-progress-linear :color="totalSelectedPlat() == amountTotalPlat
                ? 'green'
                : 'var(--bpa-pt-main-green)'
                " bg-opacity="0.4" :model-value="(totalSelectedPlat() * 100) / amountTotalPlat" height="25">
                <template v-slot:default>
                  <strong>{{ totalSelectedPlat() }} / {{ amountTotalPlat }} plats
                    choisis</strong>
                </template>
              </v-progress-linear>
              <br />
              <div class="bpa-front-module--service-items-row">
                <card-item v-for="plat in getPlat()" :key="plat.Id" :title="plat.Titre" :description="plat.Description" :canAdd="remainingAmountPlat() > 0"
                  :pictureUrl="plat.Photo" v-model="amountPlat[plat.Id]" :isSelected="amountPlat[plat.Id] > 0"
                  :hasQuantity="true" :maxValue="amountPlat[plat.Id] + remainingAmountPlat()"
                  :disabled="remainingAmountPlat() == 0 && amountPlat[plat.Id] == 0"
                  @click="togglePlat(plat.Id)"></card-item>
              </div>
              <br />
              <div class="bpa-front-module-heading">Dessert</div>
              <br />
              <div class="bpa-front-module--service-items-row">
                <card-item v-for="item in getDessert()" :key="item.Id" :title="item.Titre"
                  :description="item.Description" :pictureUrl="item.Photo" v-model="amountTotalPlat" :isSelected="true"
                  :hasQuantity="true" :editableQuantity="false" :canDelete="false"></card-item>
              </div>
              <div v-if="bookingStep.getMode() == Mode.Menu">
                <div class="bpa-front-module-heading">Allergie</div>
                <br />
                <div class="bpa-bdf--single-col-item">
                  <div class="el-form-item">
                    <div class="el-form-item__content"> <!----> <!----> <!----> <!----> <!----> <!---->
                      <!----> <!---->
                      <div>
                        <div class="bpa-front-form-control el-textarea"><textarea id="allergy" v-model="bookingData.clientInfo.allergy"
                            autocomplete="off"
                            placeholder="Décrivez vos allergies ou laissez ce champ vide" rows="3" class="el-textarea__inner"
                            style="min-height: 39.9333px;"></textarea><!---->
                        </div>
                      </div> <!----> <!----><!---->
                    </div>
                  </div> <!---->
                </div> <!----> <!---->
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="bpa-front-dc--footer">
        <div class="el-row">
          <div class="el-col el-col-24">
            <div class="bpa-front-tabs--foot footer-eauget">
              <p v-if="warnVege()">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                  fill="#5f6368">
                  <path
                    d="m40-120 440-760 440 760H40Zm138-80h604L480-720 178-200Zm302-40q17 0 28.5-11.5T520-280q0-17-11.5-28.5T480-320q-17 0-28.5 11.5T440-280q0 17 11.5 28.5T480-240Zm-40-120h80v-200h-80v200Zm40-100Z" />
                </svg>
                {{ TEXT_infovege }}
              </p>
              <subtotal v-if="bookingStep.getMode() == 'Normal'"></subtotal>
              <!----><button v-if="bookingStep.getMode() == Mode.Normal" type="button" @click="$emit('previousStep')"
                class="el-button bpa-front-btn bpa-front-btn__medium bpa-front-btn--borderless el-button--default">
                <!----><!----><span><svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24"
                    viewBox="0 0 24 24">
                    <rect fill="none" height="24" width="24"></rect>
                    <path
                      d="M9.7,18.3L9.7,18.3c0.39-0.39,0.39-1.02,0-1.41L5.83,13H21c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1H5.83l3.88-3.88 c0.39-0.39,0.39-1.02,0-1.41l0,0c-0.39-0.39-1.02-0.39-1.41,0L2.7,11.3c-0.39,0.39-0.39,1.02,0,1.41l5.59,5.59 C8.68,18.68,9.32,18.68,9.7,18.3z">
                    </path>
                  </svg>
                  Retour
                </span>
              </button>
              <button v-if="bookingStep.getMode() == Mode.Normal" type="button" @click="$emit('nextStep')"
                :disabled="!canGoNext()" :class="{ 'is-disabled': !canGoNext() }"
                class="el-button bpa-front-btn bpa-front-btn__medium bpa-front-btn--primary el-button--default eauget-next-step">
                <!----><!----><span>
                  Suivant :&nbsp;<strong>Boissons</strong>
                  <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24">
                    <rect fill="none" height="24" width="24"></rect>
                    <path
                      d="M14.29,5.71L14.29,5.71c-0.39,0.39-0.39,1.02,0,1.41L18.17,11H3c-0.55,0-1,0.45-1,1v0c0,0.55,0.45,1,1,1h15.18l-3.88,3.88 c-0.39,0.39-0.39,1.02,0,1.41l0,0c0.39,0.39,1.02,0.39,1.41,0l5.59-5.59c0.39-0.39,0.39-1.02,0-1.41L15.7,5.71 C15.32,5.32,14.68,5.32,14.29,5.71z">
                    </path>
                  </svg></span>
              </button>
              <button v-if="bookingStep.getMode() == Mode.Menu" type="button" @click="validateMenu()"
                :disabled="!canGoNext()" :class="{ 'is-disabled': !canGoNext() }"
                class="el-button bpa-front-btn bpa-front-btn__medium bpa-front-btn--primary el-button--default eauget-next-step">
                <!----><!---->
                <span>
                  <strong>Valider le choix</strong>
                  <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24">
                    <rect fill="none" height="24" width="24"></rect>
                    <path
                      d="M14.29,5.71L14.29,5.71c-0.39,0.39-0.39,1.02,0,1.41L18.17,11H3c-0.55,0-1,0.45-1,1v0c0,0.55,0.45,1,1,1h15.18l-3.88,3.88 c-0.39,0.39-0.39,1.02,0,1.41l0,0c0.39,0.39,1.02,0.39,1.41,0l5.59-5.59c0.39-0.39,0.39-1.02,0-1.41L15.7,5.71 C15.32,5.32,14.68,5.32,14.29,5.71z">
                    </path>
                  </svg></span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="bpa-fm--service__advance-options">
        <div class="bpa-sao--body">
          <!---->
          <!---->
        </div>
        <div class="bpa-sao--footer">
          <button type="button"
            class="el-button bpa-front-btn bpa-front-btn--borderless bpa-front-btn__medium el-button--default">
            <!----><!----><span>Annuler</span>
          </button>
          <button type="button"
            class="el-button bpa-front-btn bpa-front-btn__medium bpa-front-btn--primary el-button--default">
            <!----><!----><span>Continuer</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="loading">
    Chargement...
  </div>
</template>

<style scoped>
.bpa-front-dc--footer p {
  margin: 10px !important;
  color: orangered;
}

.eauget-skip-step {
  margin: auto !important;
}

@media only screen and (max-width: 576px) {
  p {
    text-align: center;
  }
}
</style>
