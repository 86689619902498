<script setup lang="ts">

const model = defineModel<number>({ required: true });

const { max, min, canDelete = true } = defineProps({
  label: String,
  disabled: Boolean,
  canDelete: Boolean,
  max: Number,
  min: Number,
});

// @TODO is-disabled when v == 0

defineEmits(['change']);

function increase() {
  if (model.value < (max || 999)) {
    model.value++;
  }
}

function decrease() {
  if (model.value > (min || 1)) {
    model.value--;
  }
}

function remove() {
  model.value = 0;
}

function onInputChange(event: any) {
  /*model.value = Math.max(0, Number.parseInt(event.target.value.replace(/[^0-9-]+/g, '')));
  event.target.value = model.value;*/
  event.target.value = model.value;
}

</script>

<template>
  <div>
    {{ label }} :
    <div class="bpa-front-form-control bpa-front-form-control--number el-input-number"><span role="button" v-if="!disabled"
        @click.stop="decrease()" class="el-input-number__decrease"><i class="el-icon-minus"></i></span><span
        role="button" v-if="!disabled" @click.stop="increase()" class="el-input-number__increase"><i class="el-icon-plus"></i></span>
      <div class="el-input"><input type="text" readonly="true" :value="model" autocomplete="off" @change="onInputChange" :disabled="disabled"
          class="el-input__inner" role="spinbutton" aria-disabled="false"></div>
    </div>
    <span v-if="canDelete" role="button" class="delete-button" @click.stop="remove()"><svg xmlns="http://www.w3.org/2000/svg" height="24px"
        viewBox="0 -960 960 960" width="24px" fill="#5f6368">
        <path
          d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" />
      </svg>
    </span>
  </div>
</template>

<style scoped>
.el-input-number {
  width: 150px;
}
.delete-button {
  display: inline-block;
  line-height: 38px;
  width: 38px;
}

.delete-button svg {
  position: relative;
  top: 6px;
  left: 6px;
}
</style>
