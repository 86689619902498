import { defineStore } from "pinia";
import { Ref, ref } from "vue";
import { useBookingStepStore } from "./bookingStep";
import { getFoods } from "@/api/api";
import { mapItemToBookingItem } from "@/services/price";

export class BookingItem {
  id!: string;
  activated!: boolean;
  quantity!: number;
  packagePrice!: string;
  unitPrice!: string;
  options!: BookingItem[];
  meta!: any;
}

export class ClientInfo {
  firstname!: string;
  lastname!: string;
}

export const useBookingDataStore = defineStore("bookingData", () => {
  const date = ref<Date | undefined>();
  const services = ref(Array<BookingItem>());
  const foods = ref(Array<BookingItem>());
  const menu = ref(Array<BookingItem>());
  const drinks = ref(Array<BookingItem>());
  const options = ref(Array<BookingItem>());
  const clientInfo = ref<any>({});
  const foodOrder = ref<any>({});
  const bookingId = ref<string>("");
  const data = localStorage.getItem("booking_data");
  const step = useBookingStepStore();
  console.log('restore data', data);
  if (data !== null) {
    const jsonData = JSON.parse(data);
    if (jsonData.timestamp > new Date().getTime() - 15 * 60 * 1000) {
      console.log('data is still valid', jsonData.timestamp);
      if (jsonData.date) {
        date.value = new Date(jsonData.date);
        if (isNaN(date.value as any) || !(date.value instanceof Date)) {
          date.value = undefined;
        }
      }

      if (jsonData.services) {
        services.value = jsonData.services;
      }

      if (jsonData.clientInfo) {
        clientInfo.value = jsonData.clientInfo;
      }

      if (jsonData.foods) {
        foods.value = jsonData.foods;
      }

      if (jsonData.menu) {
        menu.value = jsonData.menu;
      }

      if (jsonData.drinks) {
        drinks.value = jsonData.drinks;
      }

      if (jsonData.options) {
        options.value = jsonData.options;
      }
    } else {
      console.log('old data - reset STEP', jsonData.timestamp);
      step.reset();
    }
  }

  function updateService(service: BookingItem) {
    console.log('updateService', service);
    const index = services.value.findIndex(s => s.id == service.id);
    if (index > -1) {
      services.value[index] = service;
    } else {
      services.value.push(service);
    }
    console.log('SERVICES', services.value);
    if (service.id.includes("diner")) {
      foods.value = [];
      menu.value = [];
      drinks.value = [];
    }
    persist();
  }

  function updateFoods(food: BookingItem) {
    console.log('updateFoods');
    const index = foods.value.findIndex(s => s.id == food.id);
    if (index > -1) {
      if (food.quantity == 0) {
        foods.value.splice(index, 1);
      } else {
        foods.value[index] = food;
      }
    } else {
      foods.value.push(food);
    }
    menu.value = [];
    persist();
  }

  function updateInternal(category: Ref<BookingItem[]>, item: BookingItem) {
    const index = category.value.findIndex(s => s.id == item.id);
    if (index > -1) {
      if (item.quantity == 0) {
        category.value.splice(index, 1);
      } else {
        category.value[index] = item;
      }
    } else {
      category.value.push(item);
    }
    persist();
  }

  function clearMenu() {
    menu.value = [];
    persist();
  }

  function updateMenu(item: BookingItem) {
    updateInternal(menu, item);
  }

  function updateDrinks(item: BookingItem) {
    updateInternal(drinks, item);
  }

  function updateOptions(item: BookingItem) {
    updateInternal(options, item);
  }

  function updateDate(thedate: Date) {
    date.value = thedate;
    date.value.setHours(12);
    persist();
  }

  function updateClientInfo(ci: any) {
    clientInfo.value = ci;
    persist();
  }

  function getService(id: string) {
    return services.value.find(s => s.id == id);
  }

  function persist() {
    const data = getData();
    localStorage.setItem('booking_data', JSON.stringify(data));
  }

  function getData() {
    console.log('data', clientInfo.value, clientInfo.value.firstname);
    return {
      date: date.value,
      services: services.value,
      foods: foods.value,
      menu: menu.value,
      drinks: drinks.value,
      options: options.value,
      clientInfo: clientInfo.value,
      timestamp: new Date().getTime(),
      id: bookingId.value
    }
  }

  async function fetchOrder(orderData: any) {
    console.log('fetch Order', orderData);

    const foodItems = await getFoods();
    console.log('order data', orderData)
    console.log('order data2', Object.keys(orderData.data));
    if (foodItems?.success) {
      foodOrder.value = orderData;
      const f = Array<BookingItem>();
      Object.keys(orderData.data).forEach((item, index) => {
        if (item.includes('service')) {
          const i = foodItems.content?.find(f => f.Id == item);
          console.log('found item', i, foodItems.content);
          if ((parseInt(Object.values(orderData.data)[index] as string) || 0) > 0) {
            f.push(mapItemToBookingItem(i, (parseInt(Object.values(orderData.data)[index] as string) || 0)));
          }
        }
      });
      updateDate(new Date(orderData.data.Date));
      bookingId.value = orderData.data['#id'];
      foods.value = f;
      console.log('Loaded Foods', foods.value, date.value);
      if (orderData.alreadyChosen) {
        console.log('CHOSEN, also load plates', orderData.menu);
        const m = Object.entries(orderData.menu).map(e => ({ id: e[0], quantity: parseInt(e[1] as string) || 0, unitPrice: "0", packagePrice: "0", activated: true, options: [], meta: {} })).filter(f => f.quantity > 0);
        console.log('menu', m.filter(f => f.quantity > 0));
        menu.value = m;
      }

    }
    // Restore booking data
    console.log('booking values', orderData.booking);
    clientInfo.value = {
      allergy: orderData.booking.Allergie,
      comment: orderData.booking.Notes,
      email: orderData.booking.Email
    };
  }

  function setBookingId(id: string) {
    console.log('setBookingId', id);
    bookingId.value = id;
  }

  function canGoMenu2() {
    let canGo = false;
    if (date.value !== undefined) {
      canGo = foods.value.every((f: any) => {
        const d: any = new Date(f.meta['Fin menu']);
        if (!isNaN(d) && d instanceof Date) {
          return d > date.value!;
        }
        return false;
      });
    }
    step.allowMenu2 = canGo;
    return canGo;
  }

  function onlyFondue() {
    return date.value?.getDay() == 2 && !(date.value?.getFullYear() == 2024 && date.value?.getMonth() == 11 && date.value?.getDate() == 24);
  }

  function canGoMenu() {
    const canGo = services.value.some(s => s.id == 'diner' && s.quantity > 0);
    step.allowMenu = canGo;
    return canGo;
  }

  function canGoOptions() {
    return services.value.some(s => s.id == 'logement' && s.quantity > 0);

  }

  return { services, foods, menu, drinks, options, clientInfo, date, bookingId, onlyFondue, canGoMenu, canGoOptions, canGoMenu2, clearMenu, setBookingId, fetchOrder, getData, updateService, updateFoods, updateMenu, updateDrinks, updateOptions, updateDate, getService, updateClientInfo };
})
