export enum Steps {
  Date = 'Date',
  Type = 'Type',
  Menu = 'Menu',
  Menu2 = 'Menu2',
  Drinks = 'Drinks',
  Options = 'Options',
  Info = 'Info',
  Summary = 'Summary',
  SummaryMenu = 'SummaryMenu',
  Confirmation = 'Confirmation'
}
