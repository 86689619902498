<script setup lang="ts">
import { getDrinks } from '@/api/api';
import { useBookingDataStore } from '@/stores/bookingData';
import { onMounted, ref } from 'vue';


const drinks = ref(Array());

const bookingData = useBookingDataStore();

onMounted(async () => {
  const s = await getDrinks();
  if (s?.success && s.content) {
    drinks.value = s.content;
  }
});

function getDrinkName(id: string) {
  return drinks.value.find(f => f.Id == id)?.Titre || '';
}

function orderedDrinks() {
  return bookingData.drinks.filter(v => v.quantity > 0);
}

</script>
<template>
  <div>
    <div class="bpa-front-module-heading">Boissons</div>
    <div class="menu-summary" v-for="item in orderedDrinks()" :key="item.id">{{ item.quantity }} x {{
      getDrinkName(item.id) }}</div>
    <br />

  </div>
</template>

<style scoped>
@media only screen and (max-width: 576px) {
  .menu-summary {
    margin: 0px 15px;
  }
}
</style>
