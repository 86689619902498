<script setup lang="ts">
import { useBookingDataStore } from '@/stores/bookingData';
import { onMounted, ref, useTemplateRef } from 'vue';
import ClientInfoForm from '@/components/input/client-info-form.vue'

const emit = defineEmits(['nextStep', 'previousStep'])
const bookingData = useBookingDataStore();

const firstname = ref<string>('');
const lastname = ref<string>('');

onMounted(async () => {
  // Load current data
  firstname.value = bookingData.clientInfo?.firstname || '';
  lastname.value = bookingData.clientInfo?.lastname || '';
  console.log('STORE', bookingData);
});

function getClientInfo() {
  return bookingData.clientInfo;
}

async function validateInfo() {
  console.log('booking data', bookingData, clientInfoFormRef.value);
  if (clientInfoFormRef.value) {
    const validation = await clientInfoFormRef.value.triggerValidate();
    console.log('isValid ?', validation);
    if (validation.valid) {
      const values = clientInfoFormRef.value.getValues();
      bookingData.updateClientInfo({...values});
      emit('nextStep')
    }
  }


}

const clientInfoFormRef = useTemplateRef<typeof ClientInfoForm>('clientInfoForm');
</script>

<template>
  <div class="bpa-front-tabs--panel-body __bpa-is-active eauget-booking-step-type">
    <div class="bpa-front-default-card"><!---->
      <div class="bpa-front-dc--body">
        <div class="el-row">
          <div class="el-col el-col-24 el-col-xs-24 el-col-sm-24 el-col-md-24 el-col-lg-24 el-col-xl-24">
            <div class="bpa-front-module-container bpa-front-module--basic-details">
              <div class="el-row">
                <div class="el-col el-col-24 el-col-xs-24 el-col-sm-24 el-col-md-24 el-col-lg-24 el-col-xl-24">
                  <div class="bpa-front-module-heading">Coordonnées</div>
                </div>
              </div>
              <div class="el-row">
                <div class="el-col el-col-24">
                  <form class="el-form">
                    <div class="bpa-front-module--bd-form">
                      <div class="bpa-bd-fields-row el-row">
                        <client-info-form :initial-values="getClientInfo()" ref="clientInfoForm"></client-info-form>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bpa-front-dc--footer">
        <div class="el-row">
          <div class="el-col el-col-24">
            <div class="bpa-front-tabs--foot"><button type="button" @click="$emit('previousStep')"
                class="el-button bpa-front-btn bpa-front-btn__medium bpa-front-btn--borderless el-button--default"><!----><!----><span><svg
                    xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24">
                    <rect fill="none" height="24" width="24"></rect>
                    <path
                      d="M9.7,18.3L9.7,18.3c0.39-0.39,0.39-1.02,0-1.41L5.83,13H21c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1H5.83l3.88-3.88 c0.39-0.39,0.39-1.02,0-1.41l0,0c-0.39-0.39-1.02-0.39-1.41,0L2.7,11.3c-0.39,0.39-0.39,1.02,0,1.41l5.59,5.59 C8.68,18.68,9.32,18.68,9.7,18.3z">
                    </path>
                  </svg>
                  Retour </span></button> <button type="button" @click="validateInfo()"
                class="el-button bpa-front-btn bpa-front-btn__medium bpa-front-btn--primary el-button--default"><!----><!----><span>
                  Suivant :&nbsp;<strong>Résumé</strong> <svg xmlns="http://www.w3.org/2000/svg"
                    enable-background="new 0 0 24 24" viewBox="0 0 24 24">
                    <rect fill="none" height="24" width="24"></rect>
                    <path
                      d="M14.29,5.71L14.29,5.71c-0.39,0.39-0.39,1.02,0,1.41L18.17,11H3c-0.55,0-1,0.45-1,1v0c0,0.55,0.45,1,1,1h15.18l-3.88,3.88 c-0.39,0.39-0.39,1.02,0,1.41l0,0c0.39,0.39,1.02,0.39,1.41,0l5.59-5.59c0.39-0.39,0.39-1.02,0-1.41L15.7,5.71 C15.32,5.32,14.68,5.32,14.29,5.71z">
                    </path>
                  </svg></span></button></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
