import { Mode } from "@/api/types/mode.enum";
import { Steps } from "@/api/types/steps.enum";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useBookingStepStore = defineStore("bookingStep", () => {
  const step = ref(Steps.Date);
  const mode = ref(Mode.Normal);
  const bookingId = ref('');
  const checkoutId = ref('');
  const allowMenu2 = ref(false);
  const allowMenu = ref(false);
  console.log('mode init', mode.value, step.value);
  const steps: Steps[] = [Steps.Date, Steps.Type, Steps.Menu, Steps.Menu2, Steps.Drinks, Steps.Options, Steps.Info, Steps.Summary, Steps.SummaryMenu, Steps.Confirmation]
  const key = localStorage.getItem("booking_step");
  const key2 = localStorage.getItem("booking_mode");
  const key3 = localStorage.getItem("booking_id");
  if (key3 !== null) {
    setBookingId(key3);
  }
  if (key2 !== null) {
    setMode(key2 as Mode);
  }
  if (key !== null) {
    step.value = Steps[key as Steps];
  }

  function reset() {
    step.value = steps[0];
    const event = new Event("stepChanged");
    document.dispatchEvent(event);
  }

  function nextStep() {
    let inc = 1;
    if (!allowMenu2.value && step.value == Steps.Menu) {
      inc = 2;
    }
    if (!allowMenu.value && step.value == Steps.Type) {
      inc = 4;
    }
    const i = steps.indexOf(step.value);
    console.log('next step', i, steps[i+inc]);
    step.value = steps[i+inc];
    localStorage.setItem('booking_step', step.value);
    const event = new Event("stepChanged");
    document.dispatchEvent(event);
  }

  function goToStep(newStep: Steps, force = false) {
    const i = steps.indexOf(step.value);
    const j = steps.indexOf(newStep);
    console.log('goToStep', newStep, i, j);
    if (j < i || force) {
      step.value = steps[j];
      localStorage.setItem('booking_step', step.value);
    }
    console.log('step is set', step.value);
    const event = new Event("stepChanged");
    document.dispatchEvent(event);
  }

  function previousStep() {
    const i = steps.indexOf(step.value);
    let dec = 1;
    if (!allowMenu2.value && step.value == Steps.Drinks) {
      dec = 2;
    }
    if (!allowMenu.value && step.value == Steps.Options) {
      dec = 4;
    }
    console.log('new step', i, steps[i > dec ? i-dec : 0]);
    step.value = steps[i > dec ? i-dec : 0];
    localStorage.setItem('booking_step', step.value);
    const event = new Event("stepChanged");
    document.dispatchEvent(event);
  }

  function setMode(m: Mode) {
    console.log('set Mode', m);
    mode.value = m;
    localStorage.setItem('booking_mode', mode.value);
    if (m == Mode.Menu) {
      goToStep(Steps.Menu2, true);
    } else {
      if (step.value == Steps.SummaryMenu) {
        goToStep(Steps.Date);
      }
    }
  }

  function getMode() {
    return mode.value;
  }

  function setBookingId(id: string) {
    console.log('setBookingIdStep', id);
    bookingId.value = id;
    localStorage.setItem('booking_id', bookingId.value);
  }

  return { step, bookingId, checkoutId, allowMenu, allowMenu2, nextStep, previousStep, goToStep, reset, setMode, getMode, setBookingId };
})
