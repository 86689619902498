<template>
  <div class="wrapper">
    <div class="bpa-field-main-col el-col el-col-24 el-col-xs-24 el-col-sm-24 el-col-md-24 el-col-lg-24 el-col-xl-24">
      <div class="bpa-bdf--single-col-item">
        <div class="el-form-item">
          <div class="el-form-item__content">
            <div class="bpa-front-form-control el-input"><!----><!----><!----><!----><!----></div> <!----> <!---->
            <!---->
            <!----> <!----> <!----> <!----> <!----> <!----> <!----><!---->
          </div><label v-if="label" :for="name"><input :class="{ field_error: errorMessage, valid: meta.valid }" :id="name" type="checkbox" v-model.lazy="value"><span
              class="">{{ label }}</span></label>

        </div> <!---->
      </div> <!----> <!---->
    </div>
  </div>
</template>

<script setup>
import { useField } from 'vee-validate'

const props = defineProps({
  name: {
    type: String,
    required: true
  },
  type: String,
  placeholder: String,
  label: String
})

const { value, errorMessage, meta } = useField(() => props.name)
</script>
<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  gap: 3px;
}

.error_message {
  color: red;
}

.field_error {
  border: 1px solid red !important;
}

.input {
  width: 100%;
  height: 30px;
  border-radius: 5px;
  padding: 10px;
  border: 0.5px solid gray;
}

.input:focus {
  outline: none;
}

.valid {
  border: 1px solid green !important;
}

label {
  font-size: 14px;
  font-weight: 600;
}
</style>
