<script setup lang="ts">
import { getMenu } from '@/api/api';
import { useBookingDataStore } from '@/stores/bookingData';
import { onMounted, ref } from 'vue';


const menus = ref(Array());

const bookingData = useBookingDataStore();

onMounted(async () => {
  const s = await getMenu(bookingData.date!);
  if (s?.success && s.content) {
    menus.value = s.content;
  }
});

function getMenuItemName(id: string) {
  return menus.value.find(f => f.Id == id)?.Titre || '';
}

function getOrderedEntrees() {
  return bookingData.menu.filter(v => v.id.includes('entree'));
}

function getOrderedPlats() {
  return bookingData.menu.filter(v => v.id.includes('plat'));
}


</script>
<template>
  <div>
    <div class="bpa-front-module-heading">Résumé de vos choix</div>
    <div class="bpa-front-module-heading">Entrees</div>
    <div class="menu-summary" v-for="item in getOrderedEntrees()" :key="item.id">{{ item.quantity }} x {{ getMenuItemName(item.id) }}</div>
    <br />
    <div class="bpa-front-module-heading">Plats</div>
    <div class="menu-summary" v-for="item in getOrderedPlats()" :key="item.id">{{ item.quantity }} x {{ getMenuItemName(item.id) }}</div>
    <br />
  </div>
</template>

<style scoped>
@media only screen and (max-width: 576px) {
  .menu-summary {
    margin: 0px 15px;
  }
}
</style>
