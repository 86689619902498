<script setup lang="ts">
import { useBookingDataStore } from '@/stores/bookingData';

const bookingData = useBookingDataStore();

</script>
<template>
  <div>
    <div class="bpa-front-module-heading">Coordonnées</div>
    <p>{{ bookingData.clientInfo?.firstname }} {{ bookingData.clientInfo?.lastname }}</p>
    <p>Adresse: {{ bookingData.clientInfo?.address }}</p>
    <p>Email: {{ bookingData.clientInfo?.email }}</p>
    <p>Téléphone: {{ bookingData.clientInfo?.phone }}</p>
    <p v-if="bookingData.clientInfo?.enterpriseName">Entreprise: {{ bookingData.clientInfo?.enterpriseName }} ({{ bookingData.clientInfo?.enterpriseNumber }})</p>

  </div>
</template>

<style scoped>
@media only screen and (max-width: 576px) {
  .menu-summary {
    margin: 0px 15px;
  }
}
</style>
