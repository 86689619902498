<script setup lang="ts">
import { getFoods } from '@/api/api';
import { itemsAmount } from '@/api/types';
import { mapItemToBookingItem } from '@/services/price';
import { useBookingDataStore } from '@/stores/bookingData';
import { useTextStore } from '@/stores/texts';
import { onMounted, ref, watch } from 'vue';

const foods = ref(Array());
const amount = ref<itemsAmount>({});
const amountTotal = ref(0);
const TEXT_fondueservice = ref('');

const bookingData = useBookingDataStore();
const textStore = useTextStore();

onMounted(async () => {
  const s = await getFoods();
  if (s?.success && s.content) {
    foods.value = s.content;
    foods.value.forEach(o => {
      amount.value[o.Id] = 0;
      watch(() => amount.value[o.Id], async (curr, prev) => {
        console.log('amount change', o.Id, curr);
        bookingData.updateFoods(mapItemToBookingItem(foods.value.find(f => f.Id == o.Id), amount.value[o.Id]));
      });
    })

    // Load current data
    if (Array.isArray(bookingData.foods)) {
      console.log('load data', bookingData.foods);
      bookingData.foods.forEach(s => {
        amount.value[s.id] = s.quantity;
      });
    }
    amountTotal.value = bookingData.services.find(s => s.id == 'diner')?.quantity || 0;
  }
  TEXT_fondueservice.value = await textStore.getText('fondueouservice') || '';
})

foods.value.forEach((f) => {
  console.log('setup food', f);

})

function currentTotal() {
  console.log('Object', Object.keys(amount.value), Object.keys(amount.value).reduce((prev, curr) => prev + amount.value[curr], 0));
  return Object.keys(amount.value).reduce((prev, curr) => prev + amount.value[curr], 0);
}

function isDisabled(type: string) {
  if (type.includes('service')) {
    return amount.value['fondue'] > 0 || bookingData.onlyFondue();
  }

  if (type.includes('fondue')) {
    return foods.value.filter(f => f.Id !== 'fondue').some((food) => amount.value[food.Id] > 0);
  }
}

function canEditQuantity(type: string) {
  return type.includes('services');
}

function toggleType(type: string) {
  if (amount.value[type] == 0 && !isDisabled(type) && currentTotal() < amountTotal.value) {
    amount.value[type] = type.includes('services') ? 1 : (bookingData.services.find(s => s.id == 'diner')?.quantity || 1);
  }
}

function canGoNext() {
  return currentTotal() == amountTotal.value;
}

function remainingAmount() {
  return amountTotal.value - currentTotal();
}

</script>

<template>
  <div class="bpa-front-tabs--panel-body __bpa-is-active eauget-booking-step-type">
    <div class="bpa-front-default-card"><!---->
      <div class="bpa-front-dc--body"><!---->
        <div class="el-row">
          <div class="el-col el-col-24 el-col-xs-24 el-col-sm-24 el-col-md-24 el-col-lg-24 el-col-xl-24">
            <div class="bpa-front-module-container bpa-front-module--service">
              <div class="bpa-front-module-heading">Choix du nombre de services</div>
              <v-progress-linear :color="currentTotal() == amountTotal ? 'green' : 'var(--bpa-pt-main-green)'" bg-opacity="0.4" :model-value="currentTotal() * 100 / amountTotal" height="25">
                <template v-slot:default>
                  <strong>{{ currentTotal() }} / {{ amountTotal }} personnes</strong>
                </template>
              </v-progress-linear>
              <br />
              <div class="bpa-front-module--service-items-row">
                <card-item v-for="(item) in foods" :key="item.Id" :title="item.Titre" :disabled="isDisabled(item.Id) || (remainingAmount() == 0 && amount[item.Id] == 0)"
                  :description="item.Description" :pictureUrl="item.Photo" v-model="amount[item.Id]" :canAdd="remainingAmount() > 0 && !isDisabled(item.Id)"
                  :isSelected="amount[item.Id] > 0" :hasQuantity="true" :editableQuantity="canEditQuantity(item.Id)"
                  :price="item.Prix" priceLabel="Prix par personne" @click="toggleType(item.Id)" :maxValue="amount[item.Id] + remainingAmount()"></card-item>
              </div>
              <p v-if="bookingData.onlyFondue()">Les menus 3 et 4 services ne sont pas disponibles le mardi.</p>
              <p v-else>{{ TEXT_fondueservice }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="bpa-front-dc--footer">
        <div class="el-row">
          <div class="el-col el-col-24">
            <div class="bpa-front-tabs--foot footer-eauget">
              <subtotal></subtotal><!----><button type="button" @click="$emit('previousStep')"
                class="el-button bpa-front-btn bpa-front-btn__medium bpa-front-btn--borderless el-button--default"><!----><!----><span><svg
                    xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24">
                    <rect fill="none" height="24" width="24"></rect>
                    <path
                      d="M9.7,18.3L9.7,18.3c0.39-0.39,0.39-1.02,0-1.41L5.83,13H21c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1H5.83l3.88-3.88 c0.39-0.39,0.39-1.02,0-1.41l0,0c-0.39-0.39-1.02-0.39-1.41,0L2.7,11.3c-0.39,0.39-0.39,1.02,0,1.41l5.59,5.59 C8.68,18.68,9.32,18.68,9.7,18.3z">
                    </path>
                  </svg>
                  Retour </span></button> <button v-if="bookingData.canGoMenu2()" type="button" @click="$emit('nextStep')" :disabled="!canGoNext()" :class="{'is-disabled': !canGoNext()}"
                class="el-button bpa-front-btn bpa-front-btn__medium bpa-front-btn--primary el-button--default eauget-next-step"><!----><!----><span>
                  Suivant :&nbsp;<strong>Menu ({{ currentTotal() }} / {{ amountTotal }} <svg v-if="currentTotal() == amountTotal" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>)</strong> <svg xmlns="http://www.w3.org/2000/svg"
                    enable-background="new 0 0 24 24" viewBox="0 0 24 24">
                    <rect fill="none" height="24" width="24"></rect>
                    <path
                      d="M14.29,5.71L14.29,5.71c-0.39,0.39-0.39,1.02,0,1.41L18.17,11H3c-0.55,0-1,0.45-1,1v0c0,0.55,0.45,1,1,1h15.18l-3.88,3.88 c-0.39,0.39-0.39,1.02,0,1.41l0,0c0.39,0.39,1.02,0.39,1.41,0l5.59-5.59c0.39-0.39,0.39-1.02,0-1.41L15.7,5.71 C15.32,5.32,14.68,5.32,14.29,5.71z">
                    </path>
                  </svg></span></button>
                  <button v-if="!bookingData.canGoMenu2()" type="button" @click="$emit('nextStep')" :disabled="!canGoNext()" :class="{'is-disabled': !canGoNext()}"
                class="el-button bpa-front-btn bpa-front-btn__medium bpa-front-btn--primary el-button--default eauget-next-step"><!----><!----><span>
                  Suivant :&nbsp;<strong>Boissons </strong> <svg xmlns="http://www.w3.org/2000/svg"
                    enable-background="new 0 0 24 24" viewBox="0 0 24 24">
                    <rect fill="none" height="24" width="24"></rect>
                    <path
                      d="M14.29,5.71L14.29,5.71c-0.39,0.39-0.39,1.02,0,1.41L18.17,11H3c-0.55,0-1,0.45-1,1v0c0,0.55,0.45,1,1,1h15.18l-3.88,3.88 c-0.39,0.39-0.39,1.02,0,1.41l0,0c0.39,0.39,1.02,0.39,1.41,0l5.59-5.59c0.39-0.39,0.39-1.02,0-1.41L15.7,5.71 C15.32,5.32,14.68,5.32,14.29,5.71z">
                    </path>
                  </svg></span></button>
            </div>
          </div>
        </div>
      </div>
      <div class="bpa-fm--service__advance-options">
        <div class="bpa-sao--body"><!----> <!----></div>
        <div class="bpa-sao--footer"><button type="button"
            class="el-button bpa-front-btn bpa-front-btn--borderless bpa-front-btn__medium el-button--default"><!----><!----><span>Annuler</span></button>
          <button type="button"
            class="el-button bpa-front-btn bpa-front-btn__medium bpa-front-btn--primary el-button--default"><!----><!----><span>Continuer</span></button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
