<script setup lang="ts">
import { useBookingDataStore } from "@/stores/bookingData";
import { getAvailability } from "../../api/api";
import { onMounted, ref, watch } from "vue";
import { useTextStore } from "@/stores/texts";

const bookingData = useBookingDataStore();
const textStore = useTextStore();

let lastMonth = 0;
let lastYear = 0;

const TEXT_infodate = ref("");

let availableDates = ref(Array<Date>());

onMounted(async () => {
  lastMonth = new Date().getMonth();
  lastYear = new Date().getFullYear();
  await fetchNextMonth();
  await fetchNextMonth();
  await fetchNextMonth();
  /*
  const year = new Date().getFullYear();
  const month = new Date().getMonth() + 1;
  const v = await getAvailability(year, month);
  if (v?.success && v.content) {
    availableDates.value = v.content;
  }
  const v2 = await getAvailability(year, month + 1);
  const v3 = await getAvailability(year, month + 2);
  if (v2?.success && v2.content && v3?.success && v3.content) {
    availableDates.value.push(...v2.content, ...v3.content);
  }*/
  if (bookingData.date !== undefined) {
    selectedDate.value = bookingData.date;
  } else {
    selectedDate.value = new Date(availableDates.value[0]);
  }
  bookingData.updateDate(selectedDate.value);
  TEXT_infodate.value = (await textStore.getText("infodate")) || "";

  watch(
    () => selectedDate.value,
    async (curr, prev) => {
      if (curr !== undefined) {
        bookingData.updateDate(curr);
      }
    }
  );

  for (let i = 0; i < 9; i++) {
    await fetchNextMonth();
  }
});

async function fetchNextMonth() {
  console.log("fetchNextMonth", lastYear, lastMonth);
  const v = await getAvailability(lastYear, lastMonth + 1);
  if (v?.success && v.content) {
    availableDates.value.push(...v.content);
    lastMonth++;
    if (lastMonth == 12) {
      lastMonth = 0;
      lastYear++;
    }
  }
}

const selectedDate = ref<Date>();
</script>

<template>
  <div
    class="bpa-front-tabs--panel-body __bpa-is-active eauget-booking-step-date"
  >
    <div class="bpa-front-default-card">
      <!---->
      <div class="bpa-front-dc--body">
        <div class="el-row">
          <div
            class="el-col el-col-24 el-col-xs-24 el-col-sm-24 el-col-md-24 el-col-lg-24 el-col-xl-24"
          >
            <div
              class="bpa-front-module-container bpa-front-module--date-and-time"
            >
              <!---->
              <div class="el-row">
                <div
                  class="el-col el-col-24 el-col-xs-24 el-col-sm-24 el-col-md-24 el-col-lg-24 el-col-xl-24"
                >
                  <div class="bpa-front-module-heading">Date</div>
                  <!---->
                </div>
              </div>
              <div class="bpa-front--dt__wrapper">
                <div class="bpa-front--dt__col">
                  <div class="bpa-front--dt__calendar">
                    <!---->
                    <v-locale-provider locale="fr">
                      <v-date-picker
                        mode="date"
                        title="Sélectionnez une date"
                        ref="bkp_front_calendar"
                        v-model="selectedDate"
                        :allowed-dates="availableDates"
                        class="bpa-front-v-date-picker"
                        :masks="{ weekdays: 'WWW' }"
                        :first-day-of-week="1"
                        locale="fr"
                        is-required
                      ></v-date-picker>
                    </v-locale-provider>
                    <!-- <v-date-picker mode="date" ref="bkp_front_calendar" :min-date="jsCurrentDate" :max-date="booking_cal_maxdate" v-model="selectedDate" :disabled-dates="v_calendar_disable_dates" class="bpa-front-v-date-picker" @update:v-month-page="bpaMoveMonth" :attributes="v_calendar_attributes" :first-day-of-week="first_day_of_week" :locale="site_locale" :masks="{weekdays: 'WWW'}" is-required :model-config="modelConfig"></v-date-picker> -->
                    <!---->
                  </div>
                </div>
                <div
                  class="bpa-front--dt__col bpa-front-dt__timeslot-col bpa-front-dt-col__is-visible"
                >
                  <div class="bpa-front--dt__time-slots">
                    <!---->
                    <div class="bpa-front--dt__ts-heading">
                      <div class="bpa-front-module-heading">
                        Créneau horaire
                      </div>
                      <p>A partir de 18h</p>
                      <br />
                      <p>{{ TEXT_infodate }}</p>
                    </div>
                    <!---->
                    <!---->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bpa-front-dc--footer">
        <div class="el-row">
          <div class="el-col el-col-24">
            <div class="bpa-front-tabs--foot">
              <button
                type="button"
                @click="$emit('nextStep')"
                class="el-button bpa-front-btn bpa-front-btn__medium bpa-front-btn--primary el-button--default"
              >
                <!----><!----><span>
                  Suivant :&nbsp;<strong>Type de réservation</strong>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    enable-background="new 0 0 24 24"
                    viewBox="0 0 24 24"
                  >
                    <rect fill="none" height="24" width="24"></rect>
                    <path
                      d="M14.29,5.71L14.29,5.71c-0.39,0.39-0.39,1.02,0,1.41L18.17,11H3c-0.55,0-1,0.45-1,1v0c0,0.55,0.45,1,1,1h15.18l-3.88,3.88 c-0.39,0.39-0.39,1.02,0,1.41l0,0c0.39,0.39,1.02,0.39,1.41,0l5.59-5.59c0.39-0.39,0.39-1.02,0-1.41L15.7,5.71 C15.32,5.32,14.68,5.32,14.29,5.71z"
                    ></path></svg
                ></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
:deep(.v-date-picker-month__day--selected .v-btn) {
  background-color: var(--bpa-pt-main-green) !important;
}

:deep(.v-btn--disabled) {
  cursor: not-allowed;
  pointer-events: all;
  background: linear-gradient(
    to left top,
    transparent 47.75%,
    currentColor 49.5%,
    currentColor 50.5%,
    transparent 52.25%
  );
}

@media (max-width: 576px) {
  .bpa-front-module--date-and-time {
    display: block !important;
  }

  div.bpa-front--dt__col {
    margin: 25px;
  }

  :deep(.v-date-picker) {
    margin: auto;
  }
}
</style>
