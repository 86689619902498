<script setup lang="ts">
import { getStripePaymentStatus } from '@/api/api';
import { useBookingDataStore } from '@/stores/bookingData';
import { useBookingStepStore } from '@/stores/bookingStep';
import { onMounted, ref, watch } from 'vue';

const bookingData = useBookingDataStore();
const bookingStep = useBookingStepStore();

const status = ref<any>({});

onMounted(async () => {
  console.log('confirmation', bookingData.getData(), bookingStep.checkoutId);
  if (bookingStep.checkoutId !== '') {
    status.value = await getStripePaymentStatus(bookingStep.checkoutId);
    console.log("stripe status", status.value);
  }
  watch(() => bookingStep.checkoutId, async (curr, prev) => {
    console.log('checkout id changed', curr);
    if (curr !== undefined) {
      status.value = await getStripePaymentStatus(bookingStep.checkoutId);
      console.log("stripe status 2", status.value);
    }
  })
})

function bookingSuccess() {
  console.log('bookingSuccess', status.value)
  return status.value?.content?.payment?.status == 'succeeded';
}



</script>

<template>
  <div class="bpa-front-tabs--panel-body __bpa-is-active eauget-booking-step-type">
    <div class="bpa-front-default-card"><!---->
      <div class="bpa-front-dc--body"><!---->
        <div class="el-row">
          <div class="el-col el-col-24 el-col-xs-24 el-col-sm-24 el-col-md-24 el-col-lg-24 el-col-xl-24">
            <div v-if="Object.keys(status).length > 0" class="bpa-front-module-container bpa-front-module--service">
              <p v-if="bookingSuccess()">Merci pour votre réservation !</p>
              <p v-else>Nous n'avons pas encore pu valider votre paiement.</p>
              <p>Vous recevrez bientôt un email de confirmation reprenant les informations de votre réservation.</p>
            </div>
            <div v-else>Confirmation de votre réservation en cours...</div>
          </div>
        </div>
      </div>
      <div class="bpa-fm--service__advance-options">
        <div class="bpa-sao--body"><!----> <!----></div>
        <div class="bpa-sao--footer"><button type="button"
            class="el-button bpa-front-btn bpa-front-btn--borderless bpa-front-btn__medium el-button--default"><!----><!----><span>Annuler</span></button>
          <button type="button"
            class="el-button bpa-front-btn bpa-front-btn__medium bpa-front-btn--primary el-button--default"><!----><!----><span>Continuer</span></button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@media only screen and (max-width: 576px) {

  .menu-summary,
  p {
    margin: 0px 15px !important;
  }
}
</style>
