<script setup lang="ts">
import { getDrinks } from '@/api/api';
import { itemsAmount } from '@/api/types';
import { mapItemToBookingItem } from '@/services/price';
import { useBookingDataStore } from '@/stores/bookingData';
import { useTextStore } from '@/stores/texts';
import { onMounted, ref, watch } from 'vue';

const amountDrinks = ref<itemsAmount>({});
const drinks = ref(Array());

const TEXT_infoboisson = ref('');

const bookingData = useBookingDataStore();
const textStore = useTextStore();

onMounted(async () => {
  console.log('fetch menu');
  const s = await getDrinks();
  if (s?.success && s.content) {
    console.log("vv", s.content);
    drinks.value = s.content.filter(d => parseInt(d['Personnes minimum']) <= (bookingData.services.find(s => s.id == 'diner')?.quantity || 0));
    drinks.value.forEach(o => {
      amountDrinks.value[o.Id] = 0;
      watch(() => amountDrinks.value[o.Id], async (curr, prev) => {
        console.log('amount drink change', o.Id, curr);
        bookingData.updateDrinks(mapItemToBookingItem(drinks.value.find(f => f.Id == o.Id), amountDrinks.value[o.Id]));
      });
    })

    // Load current data
    if (Array.isArray(bookingData.drinks)) {
      console.log('load data', bookingData.drinks);
      bookingData.drinks.forEach(s => {
        amountDrinks.value[s.id] = s.quantity;
      });
    }
  }

  TEXT_infoboisson.value = await textStore.getText('infoboisson') || '';
})

function toggleDrink(type: string) {
  if (amountDrinks.value[type] == 0) {
    amountDrinks.value[type] = 1;
  }
}

function canGoNext() {
  return Object.values(amountDrinks.value).reduce(
    (p: number, c: number) => p + c,
    0
  ) > 0;
}
</script>

<template>
  <div class="bpa-front-tabs--panel-body __bpa-is-active eauget-booking-step-type">
    <div class="bpa-front-default-card"><!---->
      <div class="bpa-front-dc--body"><!---->
        <div class="el-row">
          <div class="el-col el-col-24 el-col-xs-24 el-col-sm-24 el-col-md-24 el-col-lg-24 el-col-xl-24">
            <div class="bpa-front-module-container bpa-front-module--service">
              <div class="bpa-front-module-heading">Choix des boissons</div>
              <div class="bpa-front-module--service-items-row">
                <card-item v-for="(item) in drinks" :key="item.Id" :title="item.Titre" :description="item.Description"
                  :pictureUrl="item.Photo" v-model="amountDrinks[item.Id]" :price="item.Prix" :maxValue="item.Maximum"
                  :isSelected="amountDrinks[item.Id] > 0" :hasQuantity="true" @click="toggleDrink(item.Id)"></card-item>
              </div>
            </div>
          </div>
        </div>
        <p>{{ TEXT_infoboisson }}</p>
      </div>
      <div class="bpa-front-dc--footer">
        <div class="el-row">
          <div class="el-col el-col-24">
            <div class="bpa-front-tabs--foot footer-eauget"><subtotal></subtotal><!----><button type="button" @click="$emit('previousStep')"
                class="el-button bpa-front-btn bpa-front-btn__medium bpa-front-btn--borderless el-button--default"><!----><!----><span><svg
                    xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24">
                    <rect fill="none" height="24" width="24"></rect>
                    <path
                      d="M9.7,18.3L9.7,18.3c0.39-0.39,0.39-1.02,0-1.41L5.83,13H21c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1H5.83l3.88-3.88 c0.39-0.39,0.39-1.02,0-1.41l0,0c-0.39-0.39-1.02-0.39-1.41,0L2.7,11.3c-0.39,0.39-0.39,1.02,0,1.41l5.59,5.59 C8.68,18.68,9.32,18.68,9.7,18.3z">
                    </path>
                  </svg>
                  Retour </span></button> <button type="button" @click="$emit('nextStep')" :disabled="!canGoNext()"
                :class="{ 'is-disabled': !canGoNext() }"
                class="el-button bpa-front-btn bpa-front-btn__medium bpa-front-btn--primary el-button--default eauget-next-step"><!----><!----><span>
                  Suivant :&nbsp;<strong>Options</strong> <svg xmlns="http://www.w3.org/2000/svg"
                    enable-background="new 0 0 24 24" viewBox="0 0 24 24">
                    <rect fill="none" height="24" width="24"></rect>
                    <path
                      d="M14.29,5.71L14.29,5.71c-0.39,0.39-0.39,1.02,0,1.41L18.17,11H3c-0.55,0-1,0.45-1,1v0c0,0.55,0.45,1,1,1h15.18l-3.88,3.88 c-0.39,0.39-0.39,1.02,0,1.41l0,0c0.39,0.39,1.02,0.39,1.41,0l5.59-5.59c0.39-0.39,0.39-1.02,0-1.41L15.7,5.71 C15.32,5.32,14.68,5.32,14.29,5.71z">
                    </path>
                  </svg></span></button></div>
          </div>
        </div>
      </div>
      <div class="bpa-fm--service__advance-options">
        <div class="bpa-sao--body"><!----> <!----></div>
        <div class="bpa-sao--footer"><button type="button"
            class="el-button bpa-front-btn bpa-front-btn--borderless bpa-front-btn__medium el-button--default"><!----><!----><span>Annuler</span></button>
          <button type="button"
            class="el-button bpa-front-btn bpa-front-btn__medium bpa-front-btn--primary el-button--default"><!----><!----><span>Continuer</span></button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
