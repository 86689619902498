<script setup lang="ts">
import sidebar from './sidebar.vue'
import stepType from './steps/step-type.vue'
import stepDate from './steps/step-date.vue'
import stepMenu from './steps/step-menu.vue'
import stepMenu2 from './steps/step-menu2.vue'
import stepDrinks from './steps/step-drinks.vue'
import stepOptions from './steps/step-options.vue'
import stepInfo from './steps/step-info.vue'
import { onMounted } from 'vue';
import { useBookingStepStore } from '@/stores/bookingStep'
import { Steps } from '@/api/types/steps.enum'
import StepSummary from './steps/step-summary.vue'
import { useRoute, useRouter } from 'vue-router'
import { Mode } from '@/api/types/mode.enum'
import { useBookingDataStore } from '@/stores/bookingData'
import StepSummaryMenu from './steps/step-summary-menu.vue'

const step = useBookingStepStore();
const bookingData = useBookingDataStore();
const router = useRouter();
const route = useRoute();

onMounted(async () => {
  await router.isReady();
  if (route.query.mode == Mode.Menu.toLowerCase()) {
    bookingData.setBookingId(route.query.token as string);
    step.setBookingId(route.query.token as string);
    step.setMode(Mode.Menu);
  } else {
    step.setMode(Mode.Normal);
  }

  if(route.query.step == 'stripe') {
    console.log('stripe callback', route.query.session_id);
    step.checkoutId = route.query.session_id as string;
    step.goToStep(Steps.Confirmation, true);
  } else if(step.step == Steps.Confirmation) {
    step.reset();
  }
})

function nextStep() {
 step.nextStep();
}

function previousStep() {
  step.previousStep();
}

function goToStep(newStep: Steps) {
  console.log('goToStep', newStep);
  step.goToStep(newStep);
}
</script>

<template>
  <sidebar v-if="step.getMode() == Mode.Normal && step.step !== Steps.Confirmation" :step="step.step" @goToStep="goToStep" />
  <stepDate @next-step="nextStep" @previous-step="previousStep" v-if="step.step == Steps.Date"></stepDate>
  <stepType @next-step="nextStep" @previous-step="previousStep" v-if="step.step == Steps.Type"></stepType>
  <stepMenu @next-step="nextStep" @previous-step="previousStep" v-if="step.step == Steps.Menu"></stepMenu>
  <stepMenu2 @next-step="nextStep" @previous-step="previousStep" v-if="step.step == Steps.Menu2"></stepMenu2>
  <stepDrinks @next-step="nextStep" @previous-step="previousStep" v-if="step.step == Steps.Drinks"></stepDrinks>
  <stepOptions @next-step="nextStep" @previous-step="previousStep" v-if="step.step == Steps.Options"></stepOptions>
  <stepInfo @next-step="nextStep" @previous-step="previousStep" v-if="step.step == Steps.Info"></stepInfo>
  <StepSummary @next-step="nextStep" @previous-step="previousStep" v-if="step.step == Steps.Summary"></StepSummary>
  <StepSummaryMenu v-if="step.step == Steps.SummaryMenu"></StepSummaryMenu>
  <StepConfirmation v-if="step.step == Steps.Confirmation"></StepConfirmation>
</template>

<style scoped>
h1 {
  font-weight: 500;
  font-size: 2.6rem;
  position: relative;
  top: -10px;
}

h3 {
  font-size: 1.2rem;
}

.greetings h1,
.greetings h3 {
  text-align: center;
}

@media (min-width: 1024px) {

  .greetings h1,
  .greetings h3 {
    text-align: left;
  }
}
</style>
