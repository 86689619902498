import { getTexts } from '@/api/api';
import { defineStore } from "pinia";
import { ref } from "vue";

export const useTextStore = defineStore("textStore", () => {
  const texts = ref<Record<string, string>>({});

  async function getText(id: string) {
    if (Object.keys(texts.value).length == 0) {
      const data = await getTexts();
      if (data?.success && data.content) {
        data.content.forEach(t => {
          texts.value[t.Id] = t.Texte;
        });
      }
    }
    return texts.value[id];
  }
  return { getText };
})
