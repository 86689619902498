<script setup lang="ts">
import { getTotalPrice } from '@/services/price';
import { useBookingDataStore } from '@/stores/bookingData';

const bookingData = useBookingDataStore();
</script>

<template>
  <div class="subtotal">Total: {{ getTotalPrice(bookingData.services, bookingData.foods, bookingData.drinks, bookingData.options) }} €</div>
</template>
